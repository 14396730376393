import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { AuthService } from 'services/auth-service';

@autoinject()
export class Index {
    private router: Router;

    constructor(private readonly authService: AuthService) {
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'home' },
            { route: 'home', name: 'home', moduleId: PLATFORM.moduleName('./home/index'), title: 'Home' },
            { route: 'carehomes', name: 'careHomes', moduleId: PLATFORM.moduleName('./carehomes/index'), title: 'Care Homes', nav: true },
            { route: 'packing-groups', name: 'packingGroups', moduleId: PLATFORM.moduleName('./packing-groups/index'), title: 'Packing Groups', nav: true },
            { route: 'sub-groups', name: 'subGroups', moduleId: PLATFORM.moduleName('./sub-groups/index'), title: 'Sub Groups', nav: true },
        ]);
        this.router = router;
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }
}
