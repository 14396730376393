import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { connectTo } from 'aurelia-store';
import { IEntity, ResultPager } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';
import { subGroupFullTextAction, subGroupSelectedRowAction } from 'services/state/actions';
import { IState } from 'services/state/state';

@connectTo<IState>({
    selector: (store) => store.state.pipe(pluck('plugins', 'blister', 'fulltext', 'subGroups')),
    target: 'state'
})
@autoinject()
export class Index extends GenericEntitySearch {

    private readonly entityRoute = 'subGroup';

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService) {
        super();

        this.pager = new ResultPager(async (page: number, size: number) => {

            const filter = this.filter.trim();

            this.isWaiting = true;
            const response = await this.repository.filterSubGroup(filter, page, size);
            this.isWaiting = false;

            await subGroupSelectedRowAction();
            this.setSelectedRow(undefined);

            this.gridDataset = response.results.map((entity: ImpiloDb.Tables.SubGroup) => {
                return entity;
            });

            return response;

        }, () => subGroupFullTextAction(this.filter, this.gridDataset, {
            from: this.pager.from,
            to: this.pager.to,
            itemsPerPage: this.pager.itemsPerPage,
            pageCount: this.pager.pageCount,
            pageNumber: this.pager.pageNumber,
            totalItems: this.pager.totalItems
        }));
    }

    async activate() {
        await this.authService.getCurrentPharmacy();
    }

    get showScreen() {
        return !this.showScreenInactivePharmacy;
    }

    get showScreenInactivePharmacy() {
        return !this.authService.isPharmacyActive();
    }

    attachedDone() {
        if (this.pager) {
            return this.pager.gotoFirst();
        }
    }

    // abstract defineGrid();
    defineGrid() {
        this.gridColumns = [
            {
                id: 'edit',
                field: 'edit',
                excludeFromColumnPicker: true,
                excludeFromGridMenu: true,
                excludeFromHeaderMenu: true,
                formatter: Formatters.editIcon,
                minWidth: 30,
                maxWidth: 30,
                onCellClick: (e: Event, args: OnEventArgs) => {
                    const entity = args.dataContext as IEntity;
                    this.router.navigateToRoute(this.entityRoute, { guid: entity.guid });
                }
            },
            { id: 'name', name: 'Sub Group Name', field: 'name', sortable: true },
            { id: 'packingGroup', name: 'Packing Group Name', field: 'packingGroup.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'careHome', name: 'Care Home Name', field: 'careHome.name', sortable: true, formatter: Formatters.complexObject },
            { id: 'isActive', name: 'Active', field: 'isActive', sortable: true , formatter: Formatters.checkmark},
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            }
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        this.router.navigateToRoute(this.entityRoute, { guid: entity.guid });
    }

    // abstract newEntity(): void;
    newEntity() {
        this.router.navigateToRoute(this.entityRoute, { guid: 'new' });
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event, args) {
        return subGroupSelectedRowAction(args.rows[0]);
    }
}
