import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    isPharmacyAllowedTo: { isAllowed: boolean, problem: string, solution: string };

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly router: Router, private readonly authService: AuthService) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.CareHome();

        const entity = this.entity as ImpiloDb.Tables.CareHome;
        entity.pharmacyGuid = this.authService.currentPharmacyGuid();
        entity.orderBy = 99999;
    }

    bind() {
        return this.controller.validate();
    }

    get canCreate() {
        this.cancelled = !this.isPharmacyAllowedTo.isAllowed;
        return this.isPharmacyAllowedTo.isAllowed;
    }

    navigateToAccount() {
        this.router.navigateToRoute('account');
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        this.isPharmacyAllowedTo = await (this.repository as ImpiloRepository).isPharmacyAllowedTo('createCareHome');
        if (this.isPharmacyAllowedTo.isAllowed) {
            await this.authService.getCurrentPharmacy();
            if (isGuid(params.guid)) {
                this.entity = await this.entity.get(this.repository, params.guid) as ImpiloDb.Tables.CareHome;
            }
        }
    }

    // abstract deactivate();
    deactivate() {
        //
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('careHomes');
    }

    // abstract get displayName();
    @computedFrom('entity.name')
    get displayName() {
        const entity = this.entity as ImpiloDb.Tables.CareHome;
        if (entity.name) {
            return `${entity.name}`;
        }

        return '';
    }
}
